export default interface SharingRequest {
  id?: number;
  period: string;
  invoice: string;
  total: number;
  supplierName: string;
  processingDate: string;
  status: keyof typeof SharingRequestStatus;
  errorMessage: string;
}

export enum SharingRequestStatus {
  NEW = 'NEW',
  SENT = 'SENT',
  ERROR = 'ERROR',
  OK = 'OK',
}
