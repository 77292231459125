import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

interface DateDisplayProps {
  dateStr: string;
}

export default function DateDisplay(props: DateDisplayProps) {
  const { dateStr } = props;
  const { i18n } = useTranslation('phones');
  const value = dateStr
    ? DateTime.fromISO(dateStr, { zone: 'America/Recife' }).toLocaleString(DateTime.DATE_SHORT, {
        locale: i18n.language,
      })
    : '---';
  return <Typography>{value}</Typography>;
}
