/* eslint-disable react/jsx-props-no-spreading */
import Page, { PAGE_ROLES } from '@constants/pages';
import isAuthorized from '@helpers/authorization';
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledSidebarItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelInfo?: string;
  labelText: string;
  isRoot?: boolean;
  quant?: number;
  active?: boolean;
  childComponent?: JSX.Element;
  badgeColor?: 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning';
  name?: Page;
};

const StyledSidebarItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    display: 'block ',
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingLeft: 0,
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: '#fcefe3',
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: '#FFFFFF',
      color: '#e3742f',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: 0,
    },
  },
  [`& .${treeItemClasses.label}`]: {
    paddingLeft: 0,
  },
}));

function SidebarItem(props: StyledSidebarItemProps) {
  const { bgColor, color, labelInfo, labelText, isRoot, quant, badgeColor, active, childComponent, name, ...other } =
    props;
  const { keycloak } = useKeycloak();
  if (name && PAGE_ROLES[name]) {
    if (!isAuthorized(keycloak, PAGE_ROLES[name])) {
      return null;
    }
  }
  return (
    <StyledSidebarItemRoot
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            pl: '1rem',
            mb: 1,
            borderLeft: active ? '2px solid orange' : '',
          }}
        >
          {isRoot === true ? (
            <Typography variant="body2" sx={{ fontWeight: '900', flexGrow: 1, fontSize: 14 }}>
              {labelText}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontWeight: '300', flexGrow: 1, fontSize: 14 }}>
              {labelText}
            </Typography>
          )}
          <Badge badgeContent={quant} color={badgeColor} />
          {childComponent}
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

export default SidebarItem;
