import { CostCenter } from '@models/costCenter';
import SearchIcon from '@mui/icons-material/Search';
import { useCostCentersSearchNameQuery } from '@services/costCenterApi';

import { Autocomplete, Box, InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useThrottle } from 'react-use';

const ON_CHANGE_DELAY = 500; // 0.5 second

interface SearchCostCenterProps {
  label: string;
  costCenter: CostCenter | null;
  onChangeCostCenter: (newCostCenter: CostCenter) => void;
}

export default function SearchCostCenter(props: SearchCostCenterProps) {
  const { label, costCenter, onChangeCostCenter } = props;

  const [inputValue, setInputValue] = useState('');
  const throttledSearchTerm = useThrottle(inputValue, ON_CHANGE_DELAY);

  const { data: costCentersByName } = useCostCentersSearchNameQuery({ query: throttledSearchTerm });

  return (
    <Box sx={{ mb: 2 }}>
      {label && <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}</Typography>}
      <Autocomplete
        value={costCenter}
        freeSolo
        fullWidth
        clearOnEscape
        filterOptions={(option) => option}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        size="small"
        onChange={(event, newValue) => {
          if (newValue) {
            const newCostsCenter = newValue as CostCenter;
            onChangeCostCenter(newCostsCenter);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={costCentersByName ? costCentersByName.content.map((option: CostCenter) => option) : []}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option ? `${option.code} - ${option.name}` : '';
        }}
        renderOption={(params, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
            {option.code} - {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name="cost-center-search"
            sx={{
              '& input': {
                marginY: 0.5,
              },
            }}
            size="small"
            style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
            ref={params.InputProps.ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                paddingRight: '10px',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '10px',
                fontSize: '13px',
              },
            }}
            autoFocus
            placeholder="Pesquisar por nome ou código do centro de custo"
          />
        )}
      />
    </Box>
  );
}
