import Role from '@constants/roles';

enum Page {
  ROOT = 'ROOT',
  LOGIN = 'LOGIN',
  PHONES = 'PHONES',
  SHARING_REQUEST = 'SHARING_REQUEST',
  CONFIG = 'CONFIG',
  PERMISSIONS = 'PERMISSIONS',
}

/**
 * Since every page is accessed only if the user owns certain roles,
 * we must define a set of Pages and the roles of every page
 */

export const PAGE_ROLES: Record<Page, Array<string>> = {
  ROOT: [Role.SUPER, Role.ADMIN, Role.CONTROLLERSHIP, Role.SHARED],
  LOGIN: [Role.SUPER, Role.ADMIN, Role.CONTROLLERSHIP, Role.SHARED],
  PHONES: [Role.SHARED, Role.CONTROLLERSHIP],
  SHARING_REQUEST: [Role.CONTROLLERSHIP],
  CONFIG: [Role.SUPER],
  PERMISSIONS: [Role.SUPER, Role.ADMIN],
};

export const PAGE_PATHS: Record<Page, string> = {
  ROOT: '/',
  LOGIN: '/login',
  PHONES: '/phones',
  SHARING_REQUEST: '/sharing-request',
  CONFIG: '/config',
  PERMISSIONS: '/permissions',
};

export default Page;
