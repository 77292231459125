import { FormControl, OutlinedInput, Typography } from '@mui/material';

interface AccountFieldProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
}

export default function AccountField(props: AccountFieldProps) {
  const { label, value, onChange } = props;
  return (
    <FormControl fullWidth>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <OutlinedInput
        sx={{
          height: 35,
        }}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
      />
    </FormControl>
  );
}
