import PageTitle from '@components/common/pageTitle';
import NewEditConfigScreen from '@components/config/newEditScreen';
import Config from '@models/config';
import { Box } from '@mui/material';
import { useGetConfigQuery } from '@services/configApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ConfigPage() {
  const { t } = useTranslation('config');

  const [selectedItem, setSelectedItem] = useState<Config | null>(null);

  const { data: getConfig } = useGetConfigQuery();

  useEffect(() => {
    setSelectedItem(getConfig || null);
  }, [getConfig]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <PageTitle title={t('Configurações')} />
      <NewEditConfigScreen config={selectedItem} />
    </Box>
  );
}
