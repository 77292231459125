import PaginationResponse from '@models/pagination';
import Person from '@models/person';
import api from '@services/api';

interface QueryInput {
  query: string;
  page: number;
  size: number;
}

export const personApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPagedPerson: builder.query<PaginationResponse<Person>, QueryInput>({
      query: (queryInput) => ({
        url: `/telecom/person/search?searchTerm=${queryInput.query}&page=${queryInput.page}&size=${queryInput.size}&sortKey=name&sortDirection=ASC`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Person'],
    }),
  }),
});

export const { useGetPagedPersonQuery } = personApi;
