import { FormControl, InputBaseComponentProps, OutlinedInput, Typography } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

interface MonetaryMaskProps extends InputBaseComponentProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

const MonetaryMask = React.forwardRef<HTMLInputElement, MonetaryMaskProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="num"
      blocks={{
        num: {
          mask: Number,
          thousandsSeparator: '.',
          radix: ',',
          scale: 2,
          padFractionalZeros: true,
          normalizeZeros: true,
          min: 0,
          max: 999999999,
        },
      }}
      placeholder="0,00"
      unmask
      inputRef={ref}
      onAccept={(value: string) => {
        onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement>);
      }}
      overwrite
    />
  );
});

MonetaryMask.displayName = 'TextMaskCustom';

interface AmountFieldProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
}

export default function AmountField(props: AmountFieldProps) {
  const { label, value, onChange } = props;

  return (
    <FormControl>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <OutlinedInput
        sx={{
          height: 35,
        }}
        value={value ? String(value) : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value ? Number(event.target.value) : 0);
        }}
        inputComponent={MonetaryMask}
        inputProps={{ 'aria-label': label }}
      />
    </FormControl>
  );
}
