import { FormControl, OutlinedInput, Typography } from '@mui/material';

interface InvoiceFieldProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  disabled: boolean;
}

export default function InvoiceField(props: InvoiceFieldProps) {
  const { label, value, onChange, disabled } = props;
  return (
    <FormControl>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <OutlinedInput
        sx={{
          height: 35,
          '&.Mui-disabled': {
            background: '#FAFAFA',
            color: '#888888',
            borderColor: '#D9D9D9',
          },
        }}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        inputProps={{ maxLength: 255 }}
        disabled={disabled}
      />
    </FormControl>
  );
}
