import { CostCenter } from '@models/costCenter';
import PaginationResponse from '@models/pagination';
import api from '@services/api';

interface QueryInput {
  query: string;
}

export const costCenterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    costCentersSearchName: builder.query<PaginationResponse<CostCenter>, QueryInput>({
      query: (queryInput) => ({
        url: `/telecom/project/search?searchTerm=${queryInput.query}&page=0&size=10&sortKey=name&sortDirection=ASC`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['CostCenter'],
    }),
  }),
});

export const { useCostCentersSearchNameQuery } = costCenterApi;
