import StandardDialog from '@/components/common/StandardDialog';
import Config from '@models/config';
import { LoadingButton } from '@mui/lab';
import { Card, CardActions, CardContent, Grid } from '@mui/material';
import { useCreateUpdateConfigMutation } from '@services/configApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountField from './accountField';
import CostCenterField from './costCenterField';
import ProtocolTypeField from './protocolTypeField';

interface NewEditConfigProps {
  config: Config | null;
}

export default function NewEditConfigScreen(props: NewEditConfigProps) {
  const { t } = useTranslation('config');
  const { config } = props;
  const [accountCode, setAccountCode] = useState('');
  const [costCenterCode, setCostCenterCode] = useState('');
  const [protocolRequestType, setProtocolRequestType] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const [createUpdateConfig, { isLoading }] = useCreateUpdateConfigMutation();

  useEffect(() => {
    if (config) {
      setAccountCode(config.accountCode);
      setCostCenterCode(config.costCenterCode);
      setProtocolRequestType(config.protocolRequestType);
    }
  }, [config]);

  useEffect(() => {
    const enabled = accountCode && costCenterCode && protocolRequestType;
    setSaveDisabled(!enabled);
  }, [accountCode, costCenterCode, protocolRequestType]);

  const handleSaveConfig = async () => {
    const newEditConfig = {
      accountCode,
      costCenterCode,
      protocolRequestType,
    } as Config;

    const response = await createUpdateConfig(newEditConfig);
    if ('data' in response) {
      toast.success(
        t('Configurações salva com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <>
      <Card sx={{ width: '95%', p: 2, boxShadow: 3, mr: 5 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <AccountField label={t('Conta')} value={accountCode} onChange={(newValue) => setAccountCode(newValue)} />
            </Grid>
            <Grid item xs={3}>
              <CostCenterField
                label={t('Centro de Custo')}
                value={costCenterCode}
                onChange={(newValue) => setCostCenterCode(newValue)}
              />
            </Grid>
            <Grid item xs={6}>
              <ProtocolTypeField
                label={t('Tipo de Protocolo')}
                value={protocolRequestType}
                onChange={(newValue) => setProtocolRequestType(newValue)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ p: 2 }}>
          <LoadingButton
            variant="contained"
            color="secondary"
            sx={{ textTransform: 'none', fontWeight: 800 }}
            loading={isLoading}
            onClick={() => setOpenDialog(true)}
            disabled={saveDisabled}
          >
            {t('Salvar')}
          </LoadingButton>
        </CardActions>
      </Card>

      <StandardDialog
        title={t('Configurações')}
        contentText={t(`Tem certeza que deseja salvar estas configurações?`)}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={() => {
          setSaveDisabled(true);
          setOpenDialog(false);
          handleSaveConfig();
        }}
        cancelText={t('Não')}
        confirmText={t('Sim')}
      />
    </>
  );
}
