import { FormControl, OutlinedInput, Typography } from '@mui/material';
import React from 'react';

interface SupplyFieldProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
}

export default function SupplyField(props: SupplyFieldProps) {
  const { label, value, onChange } = props;
  return (
    <FormControl>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <OutlinedInput
        sx={{
          height: 35,
        }}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
        inputProps={{ maxLength: 255 }}
      />
    </FormControl>
  );
}
