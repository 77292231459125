import SearchCostCenter from '@components/search/costCenter';
import SearchPerson from '@components/search/person';
import { CostCenter } from '@models/costCenter';
import Person from '@models/person';
import ResponsibleType from '@models/responsibleType';
import { Box, OutlinedInput, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const CustomizedRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  color: theme.palette.grey[700],
  '&.Mui-checked': {
    color: '#F97C06',
  },
}));

interface PhoneResponsibleProps {
  option: ResponsibleType;
  person: Person | null;
  costCenter: CostCenter | null;
  projectResponsible: string;
  onChangeOption: (newOption: ResponsibleType) => void;
  onChangePerson: (newPerson: Person) => void;
  onChangeCostCenter: (newCostCenter: CostCenter) => void;
  onChangeProjectResponsible: (newValue: string) => void;
}

export default function PhoneResponsible(props: PhoneResponsibleProps) {
  const {
    option,
    person,
    costCenter,
    projectResponsible,
    onChangeOption,
    onChangePerson,
    onChangeCostCenter,
    onChangeProjectResponsible,
  } = props;

  const { t } = useTranslation('phones');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pl: 3, pr: 3 }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
        {' '}
        {t('Vincule esse(s) telefone(s) a um colaborador ou centro de custo')}
      </Typography>
      <FormControl sx={{ minHeight: 50 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={ResponsibleType.PERSON}
          value={option}
          onChange={(e, value: string) => {
            onChangeOption(ResponsibleType[value as keyof typeof ResponsibleType]);
          }}
        >
          <FormControlLabel
            value={ResponsibleType.PERSON}
            control={<CustomizedRadio />}
            label={<Typography sx={{ fontSize: 12, marginY: 1, mr: 2 }}>{t('Colaborador')}</Typography>}
          />
          <FormControlLabel
            value={ResponsibleType.PROJECT}
            control={<CustomizedRadio />}
            label={<Typography sx={{ fontSize: 12 }}>{t('Centro de Custo')}</Typography>}
          />
        </RadioGroup>
      </FormControl>
      {option === ResponsibleType.PROJECT && (
        <>
          <SearchCostCenter
            label={t('Centro de Custo')}
            costCenter={costCenter}
            onChangeCostCenter={(newCostCenter) => {
              onChangeCostCenter(newCostCenter);
            }}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{t('Colaborador Responsável (opcional)')}</Typography>
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              bgcolor: 'white',
              borderColor: 'gray',
            }}
          >
            <OutlinedInput
              id="responsible-field"
              type="text"
              value={projectResponsible}
              sx={{ height: 34, fontSize: 13 }}
              placeholder={String(t('Nome ou email do colaborador'))}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeProjectResponsible(event.target.value);
              }}
            />
          </FormControl>
        </>
      )}
      {option === ResponsibleType.PERSON && (
        <SearchPerson
          label={t('Colaborador Responsável')}
          person={person}
          onChangePerson={(newPerson) => {
            onChangePerson(newPerson);
          }}
        />
      )}
    </Box>
  );
}
