import { InputBase, InputBaseComponentProps } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps extends InputBaseComponentProps {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(##) 9####-####"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value: string) => {
        onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
      }}
      overwrite
    />
  );
});

TextMaskCustom.displayName = 'TextMaskCustom';

interface InputPhoneProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function InputPhone(props: InputPhoneProps) {
  const { value, onChange } = props;

  return (
    <InputBase
      fullWidth
      sx={{
        pl: 3,
        paddingY: 0,
        fontSize: 13,
        color: '#979797',
        border: 1,
        borderColor: '#F4F4F4',
        bgcolor: 'white',
        height: 40,
      }}
      placeholder="(99) 99999-9999"
      inputProps={{ 'aria-label': 'telefone' }}
      value={value}
      onChange={onChange}
      inputComponent={TextMaskCustom}
    />
  );
}
