import ResponsibleType from '@models/responsibleType';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface ResponsibleTypeDisplayProps {
  value: ResponsibleType;
}

export default function ResponsibleTypeDisplay(props: ResponsibleTypeDisplayProps) {
  const { value } = props;
  const { t } = useTranslation('phones');
  const formattedValue = value === ResponsibleType.PERSON ? t('Colaborador') : t('Centro de Custo');
  return <Typography>{formattedValue}</Typography>;
}
