interface MonetaryDisplayProps {
  value: number;
}

export default function MonetaryDisplay(props: MonetaryDisplayProps) {
  const { value } = props;
  const formattedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return <span>{formattedValue}</span>;
}
