import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

type OnFilterChange = (value: string) => void;

type SearchProps = {
  label?: string | null;
  placeholder: string;
  value: string;
  onFilterChange: OnFilterChange;
};

export default function SearchComponent(props: SearchProps) {
  const {
    label = '', // default value
    placeholder,
    value,
    onFilterChange,
  } = props;
  return (
    <Box>
      {label && <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>}
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          width: '45ch',
          bgcolor: 'white',
          borderColor: 'gray',
        }}
      >
        <OutlinedInput
          id="search-field"
          type="text"
          sx={{ height: 34, fontSize: 14 }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onFilterChange(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
