import PaginationResponse from '@models/pagination';
import SharingRequest from '@models/sharingRequest';
import api from '@services/api';

interface QueryInput {
  page: number;
  size: number;
}

export const sharedRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPagedSharingRequest: builder.query<PaginationResponse<SharingRequest>, QueryInput>({
      query: (queryInput: QueryInput) => ({
        url: `/telecom/sharing-request/findAll?page=${queryInput.page}&size=${queryInput.size}&sortKey=period&sortDirection=DESC`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['SharingRequest'],
    }),

    executeSharingRequest: builder.mutation<void, FormData>({
      query: (input) => ({
        url: `/telecom/sharing-request/execute`,
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['SharingRequest'],
    }),

    createSharingRequest: builder.mutation<SharingRequest, SharingRequest>({
      query: (newSharingRequest: SharingRequest) => ({
        url: '/telecom/sharing-request/create',
        method: 'POST',
        body: newSharingRequest,
      }),
      invalidatesTags: ['SharingRequest'],
    }),

    updateSharingRequest: builder.mutation<SharingRequest, SharingRequest>({
      query: (editedSharingRequest: SharingRequest) => ({
        url: '/telecom/sharing-request/update',
        method: 'PUT',
        body: editedSharingRequest,
      }),
      invalidatesTags: ['SharingRequest'],
    }),

    deleteSharingRequest: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/telecom/sharing-request/delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SharingRequest'],
    }),
  }),
});

export const {
  useGetPagedSharingRequestQuery,
  useExecuteSharingRequestMutation,
  useCreateSharingRequestMutation,
  useUpdateSharingRequestMutation,
  useDeleteSharingRequestMutation,
} = sharedRequestApi;
