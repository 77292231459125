import Period from '@models/period';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

interface PeriodDisplayProps {
  value: string;
}

export default function PeriodDisplay(props: PeriodDisplayProps) {
  const { value } = props;
  const { t } = useTranslation('phones');
  const [year, month] = value.split('-');
  const period = { month: MONTHS[Number(month) - 1], year } as Period;
  return (
    <Typography>
      {t(period.month)}/{period.year}
    </Typography>
  );
}
