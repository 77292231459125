import { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
  }
}

const themeLight: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#fafafa',
    },
    secondary: {
      main: '#f97c06',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ffc107',
    },
    error: {
      main: '#ef4747',
    },
    success: {
      main: '#28a745',
    },
    text: {
      primary: '#4b4b4b',
      secondary: '#757575',
    },
    action: {
      disabled: '#D9D9D9',
      disabledBackground: '#D9D9D9',
    },
  },
  typography: {
    fontSize: 13,
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 40,
          '&:nth-of-type(odd)': {
            backgroundColor: 'white',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#FAFAFA',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9',
            borderWidth: 1,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#888888',
            borderWidth: 1,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#888888',
            borderWidth: 1,
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            padding: '6px 8px',
            color: '#FFFFFF',
            textTransform: 'none',
            backgroundColor: '#E87305',
            fontWeight: 'bold',
            fontSize: 14,
            '&.Mui-disabled': {
              color: '#FFFFFF',
              backgroundColor: '#E8BA90',
              opacity: 0.4,
            },
            '&:hover': {
              backgroundColor: '#CF6604',
            },
            '&:focus': {
              backgroundColor: '#B54E04',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            padding: '6px 8px',
            textTransform: 'none',
            color: '#4b4b4b',
            backgroundColor: '#F5F5F5',
            fontWeight: 'bold',
            fontSize: 14,
            '&.Mui-disabled': {
              opacity: 0.4,
              color: '#4b4b4b',
              backgroundColor: '#F2F2F2',
            },
            '&:hover': {
              backgroundColor: '#DBDBDB',
            },
            '&:focus': {
              backgroundColor: '#C2C2C2',
            },
          },
        },
      ],
    },
    MuiTableCell: {},
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 'bold',
            color: '#4b4b4b',
          },
        },
      },
    },
  },
};

function themeSettings(mode: string): ThemeOptions {
  if (mode === 'light') {
    return themeLight;
  }

  return themeLight;
}

export default themeSettings;
