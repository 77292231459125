import PaginationResponse from '@models/pagination';
import Phone, { NewEditPhone } from '@models/phone';
import api from '@services/api';

interface QueryInput {
  query: string;
  page: number;
  size: number;
}

export const phoneApi = api.injectEndpoints({
  endpoints: (builder) => ({
    phones: builder.query<PaginationResponse<Phone>, QueryInput>({
      query: (queryInput: QueryInput) => ({
        url: `/telecom/phone-responsible/search?searchTerm=${queryInput.query}&page=${queryInput.page}&size=${queryInput.size}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Phone'],
    }),

    inactivePhones: builder.query<PaginationResponse<Phone>, QueryInput>({
      query: (queryInput: QueryInput) => ({
        url: `/telecom/phone-responsible/findAllInActive?searchTerm=${queryInput.query}&page=${queryInput.page}&size=${queryInput.size}`,
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Phone'],
    }),

    createPhone: builder.mutation<Phone, NewEditPhone>({
      query: (newPhone: NewEditPhone) => ({
        url: '/telecom/phone-responsible/create',
        method: 'POST',
        body: newPhone,
      }),
      invalidatesTags: ['Phone'],
    }),

    updatePhone: builder.mutation<Phone, NewEditPhone>({
      query: (editedPhone: NewEditPhone) => ({
        url: '/telecom/phone-responsible/update',
        method: 'PUT',
        body: editedPhone,
      }),
      invalidatesTags: ['Phone'],
    }),

    deletePhone: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/telecom/phone-responsible/delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Phone'],
    }),
  }),
});

export const {
  usePhonesQuery,
  useInactivePhonesQuery,
  useCreatePhoneMutation,
  useUpdatePhoneMutation,
  useDeletePhoneMutation,
} = phoneApi;
