import { PAGE_PATHS, PAGE_ROLES } from '@constants/pages';
import RequireAuth from '@features/auth/RequireAuth';
import RequireLogin from '@features/auth/RequireLogin';
import ConfigPage from '@pages/config';
import Layout from '@pages/layout';
import Login from '@pages/login';
import ManagePhonesPage from '@pages/managePhones';
import PersonProductRoles from '@pages/personProductRoles';
import Redirect from '@pages/redirecting';
import ManageSharingRequestPage from '@pages/sharingRequest';

import { Route, Routes } from 'react-router-dom';

function AppRoute() {
  return (
    <Routes>
      <Route path={PAGE_PATHS.LOGIN} element={<Login />} />
      <Route element={<RequireLogin />}>
        <Route element={<Layout />}>
          {/* public routes */}
          <Route path={PAGE_PATHS.ROOT} element={<Redirect />} />

          <Route element={<RequireAuth roles={PAGE_ROLES.PHONES} />}>
            <Route path={PAGE_PATHS.PHONES} element={<ManagePhonesPage />} />
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.SHARING_REQUEST} />}>
            <Route path={PAGE_PATHS.SHARING_REQUEST} element={<ManageSharingRequestPage />} />
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.CONFIG} />}>
            <Route path={PAGE_PATHS.CONFIG} element={<ConfigPage />} />
          </Route>

          <Route element={<RequireAuth roles={PAGE_ROLES.PERMISSIONS} />}>
            <Route path={PAGE_PATHS.PERMISSIONS} element={<PersonProductRoles />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoute;
