import { PAGE_PATHS, PAGE_ROLES } from '@constants/pages';

export interface SubMenu {
  id: number;
  label: string;
  navigate: string;
  roles: Array<string>;
}

export interface Menu {
  title: string;
  subMenus: Array<SubMenu>;
}

const MENU: Array<Menu> = [
  {
    title: 'Telefones',
    subMenus: [
      { id: 1, label: 'Lista de Telefones', navigate: PAGE_PATHS.PHONES, roles: PAGE_ROLES.PHONES },
      { id: 2, label: 'Lançamentos', navigate: PAGE_PATHS.SHARING_REQUEST, roles: PAGE_ROLES.SHARING_REQUEST },
    ],
  },
  {
    title: 'Configurações',
    subMenus: [{ id: 3, label: 'Geral', navigate: PAGE_PATHS.CONFIG, roles: PAGE_ROLES.CONFIG }],
  },
  {
    title: 'Administração',
    subMenus: [
      { id: 4, label: 'Permissões do Produto', navigate: PAGE_PATHS.PERMISSIONS, roles: PAGE_ROLES.PERMISSIONS },
    ],
  },
];

export default MENU;
