import StandardDialog from '@components/common/StandardDialog';
import PageTitle from '@components/common/pageTitle';
import AmountField from '@components/sharingRequest/newEditScreen/AmountField';
import InvoiceField from '@components/sharingRequest/newEditScreen/InvoiceField';
import SupplyField from '@components/sharingRequest/newEditScreen/SupplyField';
import PeriodSelect from '@components/sharingRequest/newEditScreen/periodSelect';
import SharingRequest from '@models/sharingRequest';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useCreateSharingRequestMutation, useUpdateSharingRequestMutation } from '@services/sharingRequestApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const NUMBER_OF_PERIODS = 6;

function getLastPeriods(numberOfPeriods: number): Array<string> {
  const date = new Date();
  const result: Array<string> = [];
  for (let i = 0; i <= numberOfPeriods; i += 1) {
    result.push(date.toISOString().substring(0, 10));
    date.setMonth(date.getMonth() - 1);
  }
  return result;
}

interface NewEditSharingRequestProps {
  sharingRequest: SharingRequest | null;
  setDrawerOpen: (open: boolean) => void;
}

export default function NewEditSharingRequestScreen(props: NewEditSharingRequestProps) {
  const { t } = useTranslation('sharing');
  const [period, setPeriod] = useState('');
  const [invoice, setInvoice] = useState('');
  const [amount, setAmount] = useState<number>(0);
  const [supplier, setSupplier] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const { sharingRequest, setDrawerOpen } = props;

  const [createSharingRequest, { isLoading: isCreateLoading }] = useCreateSharingRequestMutation();
  const [updateSharingRequest, { isLoading: isUpdateLoading }] = useUpdateSharingRequestMutation();

  useEffect(() => {
    if (sharingRequest) {
      setPeriod(sharingRequest.period);
      setInvoice(sharingRequest.invoice);
      setAmount(sharingRequest.total);
      setSupplier(sharingRequest.supplierName);
    }
  }, [sharingRequest]);

  useEffect(() => {
    const enabled = period && invoice && amount && supplier;
    setSaveDisabled(!enabled);
  }, [period, invoice, amount, supplier]);

  const handleSaveSharingRequest = async () => {
    const newEditSharingRequest = {
      id: sharingRequest ? sharingRequest.id : null,
      period,
      invoice,
      total: amount,
      supplierName: supplier,
    } as SharingRequest;

    const response = sharingRequest
      ? await updateSharingRequest(newEditSharingRequest)
      : await createSharingRequest(newEditSharingRequest);
    if ('data' in response) {
      toast.success(
        t('Lançamento salvo com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      setDrawerOpen(false);
    }
  };

  const periodOptions = (): string[] => {
    if (sharingRequest) {
      const merged = [sharingRequest.period, ...getLastPeriods(NUMBER_OF_PERIODS)];
      const uniqueSet = new Set(merged);
      const uniqueArray = [...uniqueSet];
      return uniqueArray.sort((one, two) => (one > two ? -1 : 1));
    }
    return getLastPeriods(NUMBER_OF_PERIODS);
  };

  return (
    <div
      style={{
        minWidth: 480,
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '24px',
      }}
    >
      <PageTitle title={sharingRequest ? t('Editar Lançamento') : t('Novo Lançamento')} />
      <div style={{ marginBottom: '24px' }} />
      <PeriodSelect
        label={t('Período')}
        value={period}
        options={periodOptions()}
        placeholder={t('Selecione um período')}
        onChange={(newValue: string) => {
          setPeriod(newValue);
        }}
        disabled={!!sharingRequest}
      />
      <div style={{ marginBottom: '24px' }} />
      <InvoiceField
        label={t('Código da Nota Fiscal')}
        value={invoice}
        onChange={(newValue) => setInvoice(newValue)}
        disabled={!!sharingRequest}
      />
      <div style={{ marginBottom: '24px' }} />
      <AmountField
        label={t('Valor (R$)')}
        value={amount}
        onChange={(newValue: number) => {
          setAmount(newValue);
        }}
      />
      <div style={{ marginBottom: '24px' }} />
      <SupplyField label={t('Fornecedor')} value={supplier} onChange={(newValue) => setSupplier(newValue)} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="baseline" margin={3} spacing={2}>
          <Button
            variant="secondary"
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            {t('Cancelar')}
          </Button>

          <LoadingButton
            variant="primary"
            loading={isCreateLoading || isUpdateLoading}
            onClick={() => {
              setOpenSaveDialog(true);
              setSaveDisabled(true);
            }}
            disabled={saveDisabled}
          >
            {sharingRequest ? t('Salvar') : t('Adicionar')}
          </LoadingButton>
        </Stack>
      </div>
      <StandardDialog
        title={sharingRequest ? t('Salvar Lançamento') : t('Adicionar Lançamento')}
        contentText={t('Tem certeza que deseja salvar este Lançamento?')}
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
        onConfirm={() => {
          setOpenSaveDialog(false);
          handleSaveSharingRequest();
        }}
        cancelText={t('Não')}
        confirmText={t('Sim')}
      />
    </div>
  );
}
