import Config from '@models/config';
import api from '@services/api';

export const sharedRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query<Config, void>({
      query: () => ({
        url: '/telecom/config',
        method: 'GET',
        validateStatus: (response) => response.status === 200 || response.status === 302,
      }),
      providesTags: ['Config'],
    }),

    createUpdateConfig: builder.mutation<Config, Config>({
      query: (config: Config) => ({
        url: '/telecom/config/createOrUpdate',
        method: 'POST',
        body: config,
      }),
      invalidatesTags: ['Config'],
    }),
  }),
});

export const { useGetConfigQuery, useCreateUpdateConfigMutation } = sharedRequestApi;
