import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

const DDD_END = 4;
const NUMBER_END = 14;

interface PhoneNumberListDisplayProps {
  value: Array<string>;
}

export default function PhoneNumberListDisplay(props: PhoneNumberListDisplayProps) {
  const { value: phoneNumbers } = props;
  return (
    <List sx={{ m: 0, p: 0 }}>
      {phoneNumbers.map((phoneNumber: string) => (
        <ListItemText
          disableTypography
          key={phoneNumber}
          sx={{ m: 0, p: 0 }}
          primary={`${phoneNumber.slice(0, DDD_END)} ${phoneNumber.slice(DDD_END, NUMBER_END)}`}
        />
      ))}
    </List>
  );
}
