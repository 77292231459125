import { PAGE_PATHS } from '@/app/constants/pages';
import Role from '@constants/roles';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Redirect() {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('login');

  const isSuper = keycloak.hasResourceRole(Role.SUPER, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isAdmin = keycloak.hasResourceRole(Role.ADMIN, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isControllership = keycloak.hasResourceRole(Role.CONTROLLERSHIP, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const isShared = keycloak.hasResourceRole(Role.SHARED, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);

  useEffect(() => {
    if (isControllership || isShared) {
      navigate(PAGE_PATHS.PHONES);
    } else if (isSuper) {
      navigate(PAGE_PATHS.CONFIG);
    } else if (isAdmin) {
      navigate(PAGE_PATHS.PERMISSIONS);
    } else {
      navigate(PAGE_PATHS.ROOT);
    }
  }, [isSuper, isAdmin, isControllership, isShared, navigate]);

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Typography sx={{ marginTop: '42px', marginLeft: '50px' }}>
        {t('Seu usuário não possui permissões para acessar o sistema.')}
      </Typography>
    </div>
  );
}
export default Redirect;
