import PeriodDisplay from '@components/common/display/period';
import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface PeriodSelectProps {
  label?: string | null;
  value: string;
  options: Array<string>;
  placeholder?: string | null;
  onChange: (newValue: string) => void;
  disabled: boolean;
}

export default function PeriodSelect(props: PeriodSelectProps) {
  const { label = '', value, options, placeholder = '', onChange, disabled } = props;

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    onChange(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      {label && <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}</Typography>}
      <Select
        disabled={disabled}
        variant="outlined"
        labelId="demo-simple-select-label"
        sx={{
          width: '100%',
          '&.Mui-disabled': {
            background: '#FAFAFA',
            color: '#888888',
            borderColor: '#D9D9D9',
          },
          '& .MuiSelect-select .notranslate::after': placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
                padding: 1,
                fontSize: 13,
              }
            : {},
        }}
        id="demo-simple-select"
        value={value}
        renderValue={(option: string) => <PeriodDisplay value={option} />}
        size="small"
        onChange={handleChange}
      >
        {options.map((option: string) => (
          <MenuItem key={option} value={option}>
            <PeriodDisplay value={option} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
